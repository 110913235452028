import './App.css';
import './index.css';
import YogaClass from './components/Calendar';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <nav className="bg-white fixed top-0 w-full shadow-md">
      <div className="flex items-center justify-between px-4 py-2">
        {/* Left column for the text */}
        <div className="flex-shrink-0 text-gray-800 text-sm text-right w-1/2">
          <p>Totnes Yoga Day 2023 App powered by</p>
        </div>
        {/* Right column for the larger image */}
        <div className="flex-shrink-0 ml-4 w-1/2">
        <a href="https://shiftforward.co.uk" target="_blank" rel="noopener noreferrer">
          <img
            className="h-auto w-auto float-left"
            src={process.env.PUBLIC_URL + '/sf-transparent.png'}
            alt="ShiftForward Web Apps"
          />
          </a>
        </div>
      </div>
    </nav>
      <img class="h-auto max-w-full mt-28 md:mt-28 lg:mt-28" src={process.env.PUBLIC_URL + '/Katies-Yoga-collective.png'} alt="Katie's Yoga Collective" />
      <div className="mx-auto max-w-2xl lg:text-center mt-6 mb-6">
          <h2 className="text-base font-semibold leading-7 text-red-600">Yoga Day Timetable</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Totnes Yoga Day - Saturday 23rd September 2023
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 mb-6">
          The day of yoga is an inclusive and open day presenting different styles of Yoga led by vibrant & passionate local teachers.
          </p>
        </div>
      <div className='mb-12'>
        <img
      className="h-auto max-w-full rounded-lg object-center"
      src={process.env.PUBLIC_URL + '/map.png'}
      alt="yoga day locations"
    />
    <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
<div className="sm:pr-4">
</div>
<div className="mt-2 sm:mt-0 sm:pl-4">
</div>
<div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
  <dt className="font-semibold text-gray-900"></dt>
  <dd className="mt-2 text-gray-500">
    <span className="font-medium text-gray-900">Move Forward</span>
    <br />
    16 High Street
    <br />
    Totnes, Devon, TQ9 5RY
  </dd>
</div>
<div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
  <dt className="font-semibold text-gray-900"></dt>
  <dd className="mt-2 text-gray-500">
    <span className="font-medium text-gray-900">Birdwood House</span>
    <br />
    44 High Street
    <br />
    Totnes, Devon, TQ9 5SG
  </dd>
</div>
</dl>
    </div>
      <YogaClass />
      </header>
    </div>
  );
}

export default App;
