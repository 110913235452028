// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYtkQIpZ2T8_jAv8QGGTDtfr1IqCOTgm0",
  authDomain: "kyc-yoga.firebaseapp.com",
  projectId: "kyc-yoga",
  storageBucket: "kyc-yoga.appspot.com",
  messagingSenderId: "896419010109",
  appId: "1:896419010109:web:53e9fdd10243ec25f4fc54",
  measurementId: "G-NY6MZ0YR8H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };