import { Fragment, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, InformationCircleIcon } from '@heroicons/react/24/outline'


const laraImageUrl = `${process.env.PUBLIC_URL}/Lara_in_a_circle.png`;
const natalieImageUrl = `${process.env.PUBLIC_URL}/NAtalie_in_a_circle.png`;
const katieImageUrl = `${process.env.PUBLIC_URL}/Katie_in_a_circle.png`;
const justinImageUrl = `${process.env.PUBLIC_URL}/Justin_in_a_circle.png`;
const joyImageUrl = `${process.env.PUBLIC_URL}/Joy_in_a_circle.png`;
const jadeImageUrl = `${process.env.PUBLIC_URL}/Jade_in_a_circle.png`;
const janeImageUrl = `${process.env.PUBLIC_URL}/Jane_in_a_circle.png`;
const ioneImageUrl = `${process.env.PUBLIC_URL}/Ione_in_a_circle.png`;
const ginaImageUrl = `${process.env.PUBLIC_URL}/Gina_in_a_circle.png`;
const emmaImageUrl = `${process.env.PUBLIC_URL}/Emma_in_a_circle.png`;
const adamImageUrl = `${process.env.PUBLIC_URL}/Adam__Nat_in_a_circle.png`;
const kycImageUrl = `${process.env.PUBLIC_URL}/Katies-Yoga-collective.png`;
const pippaImageUrl = `${process.env.PUBLIC_URL}/Pippa_in_a_circle.jpg`;
const chloeImageUrl = `${process.env.PUBLIC_URL}/Chloe_Cesca_in_a_circle.png`;



const meetings = [
  {
    id: 1,
    name: 'Vinyasa, Jade',
    imageUrl: jadeImageUrl,
    start: '9:30 AM',
    startDatetime: '2023-09-23T09:30',
    end: '11:00 AM',
    endDatetime: '2023-09-23T11:00',
    extraDetails: `What is the potential of your body? What is the potential of your mind? 
    Leaving everything else aside, bring your mind present. From that place feel your way into reinhabiting your body, first in stillness and then in movement. 
    Together let's explore the potential of the body; stretching, holding, strengthening, balancing and ultimately, playing.`
  },
  {
    id: 2,
    name: 'Ashtanga, Natalie',
    imageUrl: natalieImageUrl,
    start: '11:15 AM',
    startDatetime: '2023-09-23T11:15',
    end: '12:45 PM',
    endDatetime: '2023-09-23T12:45',
    extraDetails: 'There are many series of ashtanga, but we will be working on series 1. This style of yoga includes standing, inverted, twisting, balancing poses, mantras, chanting, and controlled breathing. Linking the breath with the movement allows your body to become comfortable in each pose.'
  },
  {
    id: 3,
    name: 'Lunch',
    imageUrl: kycImageUrl,
    start: '12:45 PM',
    startDatetime: '2023-09-23T12:45',
    end: '1:55 PM',
    endDatetime: '2023-09-23T13:55',
  },
  {
    id: 4,
    name: 'Myth & Lore behind Asana, Ione',
    imageUrl: ioneImageUrl,
    start: '2:00 PM',
    startDatetime: '2023-09-23T14:00',
    end: '3:00 PM',
    endDatetime: '2023-09-23T15:00',
    extraDetails: `Myth and Lore behind the Asana Practice
    After lunch come and listen to a/some legend/s about Ganesha, the elephant headed Lord, with his good natured and sagely way and full belly,
    sitting on his undersized vehicle, the mouse. What isn't there to love? There will be Myth, Mantra, Mudra. You are welcome!`
  },
  {
    id: 5,
    name: '--- Break(Massages etc.) ---',
    imageUrl: kycImageUrl,
    start: '3:00 PM',
    startDatetime: '2023-09-23T15:00',
    end: '3:30 PM',
    endDatetime: '2023-09-23T15:30',
  },
  {
    id: 6,
    name: 'Gentle Yoga, Joy',
    imageUrl: joyImageUrl,
    start: '3:30 PM',
    startDatetime: '2023-09-23T15:30',
    end: '4:45 PM',
    endDatetime: '2023-09-23T16:45',
    extraDetails: `Each Gentle Yoga class will include postures and exercises that can be modified for each individual, depending on ability and familiarity with yoga. Joy's classes will usually be a mix of gentle dynamic exercises, longer held postures and deep relaxation.`
  },
  {
    id: 7,
    name: 'Restorative Acro Yoga, Natalie & Adam',
    imageUrl: adamImageUrl,
    start: '5:00 PM',
    startDatetime: '2023-09-23T17:00',
    end: '6:30 PM',
    endDatetime: '2023-09-23T18:30',
  },
  {
    id: 8,
    name: '--- TBC - Kirtan ---',
    imageUrl: kycImageUrl,
    start: '7:30 PM',
    startDatetime: '2023-09-23T19:30',
    end: '8:30 PM',
    endDatetime: '2023-09-23T20:30',
  },
]

const meetingsTwo = [
  {
    id: 1,
    name: 'Anusura, Emma',
    imageUrl: emmaImageUrl,
    start: '9:30 AM',
    startDatetime: '2022-09-23T9:30',
    end: '11:00 AM',
    endDatetime: '2023-09-23T11:00',
    extraDetails: 'So, we begin by inviting a sense of ease and effortlessness. We will not insist on anything, rather turn towards our true, peaceful nature. The essence of who we are already that is innately well, whole and full of grace.'
  },
  {
    id: 2,
    name: 'Vinyassa, Lara',
    imageUrl: laraImageUrl,
    start: '11:15 AM',
    startDatetime: '2023-09-23T11:15',
    end: '12:45 PM',
    endDatetime: '2023-09-23T12:45',
    extraDetails: 'Lara will be teaching a 1.5/ 1 hour long dynamic vinyasa flow. These classes will include the perfect does of strength, balance and flexibility. We will warm the body wisely before exploring a  different peak pose and closing with gentle relaxation. You are not expected to make anything happen, you are just encouraged to allow whatever wants to happen to unfold in its own time and rhythm. The main aim is to just bring home the present moment one breath at a time and have fun. Expect beautiful music to accompany the practice and to perhaps get a little sweaty.'
  },
  {
    id: 3,
    name: 'Lunch',
    imageUrl: kycImageUrl,
    start: '12:45 PM',
    startDatetime: '2023-09-23T12:45',
    end: '1:55 PM',
    endDatetime: '2023-09-23T13:55',
  },
  {
    id: 4,
    name: 'Sacred Chanting, Chloe Ceska',
    imageUrl: chloeImageUrl,
    start: '2:00 PM',
    startDatetime: '2023-09-23T14:00',
    end: '3:00 PM',
    endDatetime: '2023-09-23T15:00',
    extraDetails: `Coming back to the heart of who we are with mantra. In the love, strength and holding of the mantra circle we create space to deeply listen, and tune into the vibrations of our own hearts as we chant with the heart songs of others.`
  },
  {
    id: 5,
    name: '--- Break(Massages etc.) ---',
    imageUrl: kycImageUrl,
    start: '3:00 PM',
    startDatetime: '2023-09-23T15:00',
    end: '3:30 PM',
    endDatetime: '2023-09-23T15:30',
  },
  {
    id: 6,
    name: 'The Yin of the afternoon , Libby',
    imageUrl: kycImageUrl,
    start: '3:30 PM',
    startDatetime: '2023-09-23T3:30',
    end: '4:45 PM',
    endDatetime: '2023-09-23T4:45',
    extraDetails: `A slow hip opening, introspective practice based on the theme of the Hermit archetype in the Tarot.
    A session in keeping with the Yin of the afternoon, her class will also include a short dharma talk at the beginning with pranayama & mudra`
  },
  {
    id: 7,
    name: 'Scaravelli, Justin',
    imageUrl: justinImageUrl,
    start: '5:00 PM',
    startDatetime: '2023-09-23T5:00',
    end: '6:30 PM',
    endDatetime: '2023-09-23T6:30',
    extraDetails: `Justin's Scaravelli inspired class;
    Coming to the practice both playfully and responsively, we can restore a deep fluidity to the body,
    tuning to the soft, circular waves of breath, and feeling the deep pull of the ground from beneath.
    Dropping down softly into ourselves,
    we can enter into a lived intimacy with the actual present texture of our sensations.`
  },
  {
    id: 8,
    name: '--- TBC - Kirtan ---',
    imageUrl: kycImageUrl,
    start: '7:30 PM',
    startDatetime: '2023-09-23T19:30',
    end: '8:30 PM',
    endDatetime: '2023-09-23T20:30',
  },
]

const meetingsThree = [
  {
    id: 1,
    name: 'Hatha, Katie',
    imageUrl: katieImageUrl,
    start: '9:30 AM',
    startDatetime: '2022-09-23T9:30',
    end: '11:00 AM',
    endDatetime: '2023-09-23T11:00',
    extraDetails: 'Katie will be offering a Hatha style yoga session on 23rd September, bringing you many of the facets of this extraordinary science. We will focus on breath, strength, flexibility, balance, mudra gestures, intention and rest. She will Bring in some wisdom from the sages, from the friends of the collective, and her own personal practice, a light hearted session bringing us together with the dance of our wonderful lives.'
  },
  {
    id: 2,
    name: 'Fusion Flow, Gina',
    imageUrl: ginaImageUrl,
    start: '11:15 AM',
    startDatetime: '2023-09-23T11:15',
    end: '12:45 PM',
    endDatetime: '2023-09-23T12:45',
    extraDetails: 'Letting go for Vitality! Where Kundalini awakening meets Hatha Flow. Increasing power and vitality and bringing us into the here and now! Join us for dynamic Hatha Flow class interweaved with powerful healing kriyas, energy medicine and sound technology. Ancient wisdom combined with modern science to conquer the monkey mind.Warm welcome to all levels but expect to be playfully challenged to your edge. Expect to laugh, smile, sweat.'
  },
  {
    id: 3,
    name: 'Lunch',
    imageUrl: kycImageUrl,
    start: '12:45 PM',
    startDatetime: '2023-09-23T12:45',
    end: '1:55 PM',
    endDatetime: '2023-09-23T13:55',
  },
  {
    id: 4,
    name: 'Somatic, Pippa',
    imageUrl: pippaImageUrl,
    start: '2:00 PM',
    startDatetime: '2023-09-23T14:00',
    end: '3:00 PM',
    endDatetime: '2023-09-23T15:00',
    extraDetails: `Integrated yoga,
    Somatic, scaravelli inspired, elements of hatha yoga, Pippa brings you a beautiful mix of integrated yoga which takes you on a journey with her through your experience to witness the body, mind and breath connections .`
  },
  {
    id: 5,
    name: '--- Break(Massages etc.) ---',
    imageUrl: kycImageUrl,
    start: '3:00 PM',
    startDatetime: '2023-09-23T15:00',
    end: '3:30 PM',
    endDatetime: '2023-09-23T15:30',
  },
  {
    id: 6,
    name: 'Scaravelli Inspired, Helen Bodiley',
    imageUrl: kycImageUrl,
    start: '3:30 PM',
    startDatetime: '2023-09-23T15:30',
    end: '4:45 PM',
    endDatetime: '2023-09-23T16:45',
    extraDetails: `My approach to Yoga is mainly influenced by the teachings of Vanda Scaravelli. 
    It is a practice which focuses on tapping into the inner intelligence of the body by gently cultivating a deep listening and greater awareness. 
    Working with movement and stillness, and also breath and gravity, I focus on helping to undo tensions and tightness in the yoga postures, so that students can begin to discover a freer more easeful understanding of their bodies and themselves.
    This practice is nourishing, enlivening and relaxing, and is suitable for both beginners and those with more experience.`
  },
  {
    id: 7,
    name: 'Yoga Nidra, Jane',
    imageUrl: janeImageUrl,
    start: '5:00 PM',
    startDatetime: '2023-09-23T17:00',
    end: '18:30 PM',
    endDatetime: '2023-09-23T18:30',
    extraDetails: `Yoga Nidra
    A 40 minute practice at the end of all your stretching.
    Deep systematic relaxation, going within to the place where you and I disappear.
    A brief overview of the Himalayan tradition practice, and time for a few Q & As too.`
  },
  {
    id: 8,
    name: '--- Kirtan ---',
    imageUrl: kycImageUrl,
    start: '7:30 PM',
    startDatetime: '2023-09-23T19:30',
    end: '8:30 PM',
    endDatetime: '2023-09-23T20:30',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function YogaClass() {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  function openModal(meeting, event) {
    setSelectedMeeting(meeting);
    setIsOpen(true);
    // Calculate the position based on the click event.

    // Calculate the position based on the click event.
    if (event) {
      let left = event.clientX;

      // Adjust the left position for smaller screens (e.g., screens narrower than 640px).
      if (window.innerWidth <= 640) {
        const modalWidth = 310; // Adjust this value as needed for your modal's width.
        left = Math.max(10, left - modalWidth);
      }

      setModalPosition({
        top: event.clientY,
        left,
      });
    }
  }

    function closeModal() {
      setSelectedMeeting(null);
      setIsOpen(false);
    }

  return (
    <div className="md:grid md:grid-cols-3 md:divide-x md:divide-gray-200">
      <section className="mt-12 md:mt-0 md:pl-14">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Move Forward Room 1
        </h2>
        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
          {meetings.map((meeting) => (
            <li
              key={meeting.id}
              className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
            >
              <img src={meeting.imageUrl} alt="" className="h-10 w-10 flex-none rounded-full" />
              <div className="flex-auto">
                <p className="text-gray-900">{meeting.name}</p>
                <p className="mt-0.5">
                  <time dateTime={meeting.startDatetime}>{meeting.start}</time> -{' '}
                  <time dateTime={meeting.endDatetime}>{meeting.end}</time>
                </p>
              </div>
              <div>
              <button
                onClick={(event) => openModal(meeting, event)}
                className="group-hover:opacity-100"
                title="View Details"
              >
                <InformationCircleIcon className="h-6 w-6 text-blue-800" />
              </button>
            </div>
            </li>
          ))
          }
        </ol>
        {selectedMeeting && (
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 bg-white rounded-2xl p-6 border border-gray-300"
          onClose={closeModal}
          style={{
            // Set the position dynamically based on the modalPosition state.
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
          }}
        >
            {/* Rest of your modal code here */}
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            {selectedMeeting.name}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {selectedMeeting.extraDetails}
              </p>
            </div>
            {/* Add a "Close" button to close the modal */}
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog>
        </Transition>
      )}
      </section>
      <section className="mt-12 md:mt-0 md:pl-14">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Move Forward Room 2
        </h2>
        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
          {meetingsTwo.map((meeting) => (
            <li
              key={meeting.id}
              className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
            >
              <img src={meeting.imageUrl} alt="" className="h-10 w-10 flex-none rounded-full" />
              <div className="flex-auto">
                <p className="text-gray-900">{meeting.name}</p>
                <p className="mt-0.5">
                  <time dateTime={meeting.startDatetime}>{meeting.start}</time> -{' '}
                  <time dateTime={meeting.endDatetime}>{meeting.end}</time>
                </p>
              </div>
              <div>
              <button
                onClick={(event) => openModal(meeting, event)}
                className="group-hover:opacity-100"
                title="View Details"
              >
                <InformationCircleIcon className="h-6 w-6 text-blue-800" />
              </button>
            </div>
            </li>
          ))
          }
        </ol>
        {selectedMeeting && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 bg-white rounded-2xl p-6 border border-gray-300"
            onClose={closeModal}
            style={{
              // Set the position dynamically based on the modalPosition state.
              top: `${modalPosition.top}px`,
              left: `${modalPosition.left}px`,
            }}
          >
            {/* Rest of your modal code here */}
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            {selectedMeeting.name}</Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {selectedMeeting.extraDetails}
              </p>
            </div>
            {/* Add a "Close" button to close the modal */}
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog>
        </Transition>
      )}
      </section>
      <section className="mt-12 md:mt-0 md:pl-14">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Birdwood House
        </h2>
        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
          {meetingsThree.map((meeting) => (
            <li
              key={meeting.id}
              className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
            >
              <img src={meeting.imageUrl} alt="" className="h-10 w-10 flex-none rounded-full" />
              <div className="flex-auto">
                <p className="text-gray-900">{meeting.name}</p>
                <p className="mt-0.5">
                  <time dateTime={meeting.startDatetime}>{meeting.start}</time> -{' '}
                  <time dateTime={meeting.endDatetime}>{meeting.end}</time>
                </p>
              </div>
              <div>
              <button
                onClick={(event) => openModal(meeting, event)}
                className="group-hover:opacity-100"
                title="View Details"
              >
                <InformationCircleIcon className="h-6 w-6 text-blue-800" />
              </button>
            </div>
            </li>
          ))
          }
        </ol>
        {selectedMeeting && (
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 bg-white rounded-2xl p-6 border border-gray-300"
          onClose={closeModal}
          style={{
            // Set the position dynamically based on the modalPosition state.
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
          }}
        >
            {/* Rest of your modal code here */}
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            {selectedMeeting.name}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {selectedMeeting.extraDetails}
              </p>
            </div>
            {/* Add a "Close" button to close the modal */}
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog>
        </Transition>
      )}
      </section>
      
    </div>
  )
}
